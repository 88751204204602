/**************************************************
 * Nombre:       CabeceraTabla
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Checkbox, FormControlLabel, FormGroup, Grid2, IconButton, Popover, TableCell, TableRow} from "@mui/material";
import {COLORTEXTO} from "../../../Constantes";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {CASILLA_ESTADO} from "../obtenerCasilla";
import {useState} from "react";

const CabeceraTabla = ({datosCabecera = [], estado, setEstado}) => {
    const [elemetoPadre, setElementoPadre] = useState(null)
    const [open, setOpen] = useState(false)
    const [escogido, setEcogido] = useState('Todo')
    const [cabecera, setCabecera] = useState({})

    const abrir = (e, item) => {
        setElementoPadre(e.target)
        setOpen(true)
        setCabecera(item)
    }

    const cerrar = () => {
        setElementoPadre(null)
        setOpen(false)
    }

    const escoger = (e) => {
        cerrar()
        setEcogido(e)
        setEstado(e)
    }

    return (
        <TableRow>
            {datosCabecera.map((item, index) => {

                    return (
                        <TableCell key={`re${index}`} variant={'head'} align={item.direccion}
                                   sx={{color: COLORTEXTO, fontWeight: 600, fontSize: 14}}>

                            {escogido !== 'Todo' && item.tipo === CASILLA_ESTADO ? escogido : item.nombre}

                            {item.tipo === CASILLA_ESTADO &&
                                <span style={{paddingLeft: 10}}>
                                    <IconButton
                                        onClick={(e) => abrir(e, item)}
                                        sx={{marginBottom: 0, p: 0}}>
                                        {escogido === 'Todo' ?
                                            <CheckBoxOutlineBlank color={'primary'}/>
                                            :
                                            <CheckBox color={'primary'}/>
                                        }
                                    </IconButton>

                                </span>
                            }
                        </TableCell>
                    )
                }
            )
            }

            <TableCell variant={'head'} align={'right'}
                       sx={{color: COLORTEXTO, fontWeight: 600, fontSize: 14}}>

            </TableCell>

            <Popover
                open={open}
                anchorEl={elemetoPadre}
                onClose={() => cerrar()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            boxShadow: '0px 8px 12px 0px #00000014',
                            border: 1,
                            borderColor: 'rgba(143,173,213,0.31)',
                            borderRadius: 2
                        }

                    }
                }}
            >
                <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{p: 2}}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={false}
                                                             onClick={() => escoger('Todo')}
                        />} label={'Todo'}/>

                        {cabecera?.estados?.map((it, index) => {
                            return (
                                <FormControlLabel control={<Checkbox
                                    onClick={() => escoger(it.nombre)}
                                    checked={escogido === it.nombre}/>} label={it.nombre}/>
                            )
                        })}
                    </FormGroup>
                </Grid2>


            </Popover>

        </TableRow>
    )

}
export default CabeceraTabla