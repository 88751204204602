/**************************************************
 * Nombre:       Valor_Titulo_Diferencia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/

import {Grid2, Tooltip, Typography} from "@mui/material";
import {ArrowOutwardOutlined, ArrowUpwardOutlined, DragHandleOutlined} from "@mui/icons-material";
import {ABAJO, ARRIBA, TEXTO} from "../../../ContantesColorGraficas";
import {diferenciaPorcentual} from "../../../Utilidades/diferenciaPorcentual";

const Valor_Titulo_Diferencia = ({valorActual = 0, valorAnterior = 0, titulo = ''}) => {


    return (
        <Grid2
            container
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            size={{xs: 12, sm: 12, md: 12}}>

            <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{alignItems: 'flex-start'}}>
                <Typography sx={{fontSize: {xs: 24, md: 30}, fontWeight: 700, color: TEXTO}}>
                    {valorActual}

                </Typography>
                <span style={{fontSize: 12, marginTop: 7, marginBottom: 0}}>

                    {valorAnterior !== 0 &&

                        <Tooltip placement={'top'} title={`${diferenciaPorcentual(valorActual, valorAnterior)} %`}>
                            {diferenciaPorcentual(valorActual, valorAnterior) > 0 ?
                                <ArrowOutwardOutlined
                                    sx={{fill: ARRIBA, width: {xs: 18, md: 20}, height: {xs: 18, md: 20}}}/>
                                :
                                diferenciaPorcentual(valorActual, valorAnterior) === 0 ?
                                    <DragHandleOutlined sx={{
                                        fill: '#000',
                                        width: {xs: 18, md: 20},
                                        height: {xs: 18, md: 20},
                                        transform: 'rotate(0deg)'
                                    }}/>
                                    :

                                    <ArrowUpwardOutlined sx={{
                                        fill: ABAJO,
                                        width: {xs: 18, md: 20},
                                        height: {xs: 18, md: 20},
                                        transform: 'rotate(90deg)'
                                    }}/>
                            }
                        </Tooltip>
                    }
                </span>
            </Grid2>

            <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                <Typography sx={{fontSize: 14, color: TEXTO}}>
                    {titulo}
                </Typography>
            </Grid2>

        </Grid2>
    )

}
export default Valor_Titulo_Diferencia