/**************************************************
 * Nombre:       Dona_Limpia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Chart from "react-apexcharts";
import {COLORES} from "../ContantesColorGraficas";

const Area_Limpia = ({ancho, labels = [], series=[], marginTop=0, height='auto'}) => {
    const options = {
        chart: {
            id: "area-bar",
            sparkline: {
                enabled: true,
            },

        },
        labels: labels,
        stroke: {
            width: 2,
            curve: 'smooth'
        },
        colors: COLORES,


    }



    return (
        <Chart
            options={options}
            series={series}
            type="area"
            width={ancho}
           // height={height}
            style={{marginTop:marginTop, alignItems: 'flex-end' }}
        />
    )

}
export default Area_Limpia