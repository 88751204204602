/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircleOutlined, AddAlert, AddAlertOutlined, ApartmentOutlined,
    BadgeOutlined, CakeOutlined, Flag, FlagOutlined, HomeOutlined,
    HomeWorkOutlined,
    MailOutline,
    PhoneAndroidOutlined,
    RecentActorsOutlined
} from "@mui/icons-material";
import {useEffect, useState} from "react";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOSARTICULO, ESTADOSPQRS, TIPOSPQRS} from "../../Constantes";
import IngresoFecha from "../../Modulo_formularios/Componentes/IngresoFecha";
import {obtenerDepartamentos} from "../../Modulo_Graficas/Graficas/Componentes/Mapa/Funciones/obtenerDepartamentos";
import {obtenerCiudades} from "../../Modulo_Graficas/Graficas/Componentes/Mapa/Funciones/obtenerCiudades";

const Formulario_Pqrs = ({...props}) => {
    const [departamento, setDepartamento] = useState('')


    useEffect(() => {

        let dep = props.props.getValues('departamento')
        setDepartamento(dep)

    }, [props.props.watch('departamento')]);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid2
                container
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                size={{xs: 12, sm: 12, md: 12}}
                spacing={2}
            >

                <Grid2 container size={{xs: 12, sm: 12, md: 6}}>
                    <IngresoSelect Icono={FlagOutlined} nombre={'Estado'} dato={'estado'} {...props}
                                 opciones={ESTADOSPQRS.map((it) => it.nombre)}
                    />
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 6}}>
                    <IngresoSelect Icono={AddAlertOutlined} nombre={'Tipo'} dato={'tipo'} {...props}
                                   opciones={TIPOSPQRS}
                    />
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <Typography sx={{fontSize: 14, fontWeight: 600}}>Datos personales</Typography>
                </Grid2>


                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <IngresoTexto Icono={AccountCircleOutlined} nombre={'Nombre'} dato={'nombre'} {...props}

                    />
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <IngresoTexto Icono={MailOutline} nombre={'Correo'} dato={'correo'} {...props}

                    />
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 6}}>
                    <IngresoTexto Icono={PhoneAndroidOutlined} nombre={'Num Celular'} dato={'wass'} {...props}

                    />
                </Grid2>


                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <Typography sx={{fontSize: 14, fontWeight: 600}}>Mensaje</Typography>
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <IngresoTexto  nombre={'Departamento'} dato={'mensaje'} {...props} lineas={10}

                    />
                </Grid2>


                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <Typography sx={{fontSize: 14, fontWeight: 600}}>Notas Internas</Typography>
                </Grid2>


                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <IngresoTexto nombre={'Nota Internas'} dato={'notas'} {...props}
                                  lineas={6}
                    />
                </Grid2>


            </Grid2>

        </form>

    )

}
export default Formulario_Pqrs