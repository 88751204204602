/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid2, IconButton} from "@mui/material";
import {Menu, WbSunnyOutlined} from "@mui/icons-material";
import {useContext, useEffect, useState} from "react";
import {useAnimate} from "framer-motion";
import IconoAvatarPerfil from "./SubComponentes/IconoAvatarPerfil";
import IconoNotificaciones from "./SubComponentes/IconoNotificaciones";
import IconoHistorial from "./SubComponentes/IconoHistorial";
import {contexBarra} from "../../Dashboard/Dashboard";
import {useMediaQuery} from "@mui/system";
import {theme} from "../../Tema";

const Barra = ({setOpen, open, seccionesPerfil}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [navegando, setNavegando] = useState(false)
    const [scope, animate] = useAnimate()
    const [opaco, setOpaco] = useState(1)
    const [openPerfil, setOpenPerfil] = useState(false)
    const [openPerfilNotificacion, setOpenPerfilNotificaciones] = useState(false)


    useEffect(() => {


        window.addEventListener('scroll', (e) => {
            if (window.scrollY > 50 && !navegando) {
                setNavegando(true)
            } else if (window.scrollY < 50) {
                setNavegando(false)
            }

        });


        if (scope.current && masSM) {
            setOpaco(0)
            animate(scope.current, {x: -200, transitionDuration: 1500})
            setTimeout(() => {
                setOpaco(1)
                if (scope.current) {
                    animate(scope.current, {x: 1, transitionDuration: 1500})
                }

            }, 500)
        }


    }, [open]);
    return (
        <Grid2
            container
            size={12}
            alignItems={'center'}
            sx={{
                px: masSM ? 4 : 3,
                py: 2,
                boxShadow: navegando ? 3 : 0,
                position: 'fixed',
                top: '0%',
                backgroundColor: '#fff',
                pr: open && masSM ? 36 : !masSM ? 3 : 13
            }}
        >

            <Grid2 size={{xs: 2, sm: 4, md: 1}} >
                <ButtonBase
                    onClick={() => setOpen((e) => !e)}
                >
                    <Menu/>
                </ButtonBase>

            </Grid2>

            <Grid2 size={{xs: 4, sm: 4, md: 8}}>

            </Grid2>

            <Grid2
                ref={scope}
                container size={{xs: 6, sm: 4, md: 3}} justifyContent={'flex-end'} justifyItems={'center'}
                sx={{
                    opacity: opaco,
                    pr: (openPerfil || openPerfilNotificacion) && masSM ? 1.7 : 0,
                }}
                spacing={!masSM ? 1 : 6}
            >


                <Grid2 container size={{xs: 3, sm: 2, md: 2}} justifyContent={'flex-end'}>
                    {/*
                    <IconButton>
                        <WbSunnyOutlined color={'info'} sx={{width: 20, height: 20}}/>
                    </IconButton>
                    */}
                </Grid2>

                <Grid2 container size={{xs: 3, sm: 2, md: 2}} justifyContent={'flex-end'}>
                    {/*
                    <IconoHistorial/>
                    */}
                </Grid2>

                <Grid2 container size={{xs: 3, sm: 2, md: 2}} justifyContent={'flex-end'}>
                    {/* <IconoNotificaciones color={'info'} sx={{width: 20, height: 20}}
                                         setOpenPerfilNotificaciones={setOpenPerfilNotificaciones}
                                         openPerfilNotificacion={openPerfilNotificacion}/> */}
                </Grid2>

                <Grid2 container size={{xs: 3, sm: 2, md: 2}} justifyContent={'flex-end'}
                       >
                    <IconoAvatarPerfil openPerfil={openPerfil} setOpenPerfil={setOpenPerfil}
                                       seccionesPerfil={seccionesPerfil}/>
                </Grid2>


            </Grid2>


        </Grid2>
    )

}
export default Barra