import {useEffect, useState} from "react";

export const useObtenerAlto = ({contenedor}) => {
    const [alto, setAlto] = useState(0)

    useEffect(() => {

        if (contenedor) {
            let resizable = new ResizeObserver((e) => {
                let anchoContenedor = e[0].contentRect.height
                if (anchoContenedor) {
                    setAlto(anchoContenedor)
                }
            })

            resizable.observe(contenedor.current)
        }

    }, [contenedor]);

    return {
        alto
    }
}