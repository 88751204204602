/**************************************************
 * Nombre:       Casilla_Avatar_Titulo_Dato
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Avatar, Grid2, TableCell, Typography} from "@mui/material";
import {COLORTEXTO} from "../../../Constantes";

const Casilla_Texto = ({dato, dir}) => {

    return (
        <TableCell key={dato} variant={'body'} align={dir} sx={{color: COLORTEXTO, fontSize: 14, fontWeight: 500}}>
            {dato}
        </TableCell>

    )

}
export default Casilla_Texto