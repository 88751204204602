/**************************************************
 * Nombre:       TarjetaUsuario
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Avatar, Grid2, IconButton, Typography} from "@mui/material";
import usus from '../../../Recursos/usuario_defecto.jpg'
import {PowerSettingsNew} from "@mui/icons-material";
import {useAnimate} from "framer-motion";
import {useContext, useEffect, useState} from "react";
import {useMediaQuery} from "@mui/system";
import {theme} from "../../../Tema";
import {contexBarra} from "../../../Dashboard/Dashboard";
import {usuarioContext} from "../../../App";


const TarjetaUsuario = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const cData = useContext(contexBarra)
    const cDataUsuario = useContext(usuarioContext)
    const [scope, animate] = useAnimate()
    const [opaco, setOpaco] = useState(1)

    useEffect(() => {
        if (scope.current && masSM) {
            setOpaco(0)
            animate(scope.current, {x: -200, transitionDuration: 1500})
            setTimeout(() => {
                setOpaco(1)
                animate(scope.current, {x: 1, transitionDuration: 1500})
            }, 500)
        }

    }, []);
    return (
        <Grid2
            ref={scope}
            container
            size={12}
            sx={{
                backgroundColor: theme.palette.primary.main + 10,
                borderRadius: 2,
                position: 'absolute',
                bottom: 18,
                width: '82%',
                opacity: opaco
            }}
            padding={'16px'}
            alignItems={'center'}
        >


            <Grid2 size={3}>
                <Avatar src={usus}/>
            </Grid2>

            <Grid2 container size={7} sx={{pl: 1}}>
                <Grid2 size={12}>
                    <Typography sx={{fontSize: 16, fontWeight: 600}}>{cDataUsuario?.usuario?.nombre}</Typography>
                </Grid2>

                <Grid2 size={12}>
                    <Typography sx={{fontSize: 12, fontWeight: 400}}>{cDataUsuario?.usuario?.tipo}</Typography>
                </Grid2>
            </Grid2>

            <Grid2 container size={2} justifyContent={'center'}>
                <IconButton
                    onClick={() => cData.funSalir()}
                >
                    <PowerSettingsNew color={'primary'} sx={{width: 20, height: 20, color: theme.palette.primary.main}}/>
                </IconButton>

            </Grid2>

        </Grid2>
    )

}
export default TarjetaUsuario