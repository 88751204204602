export const formatoMonedaTabla = (number, decimal=0) => {


    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,

    });

    return formatter.format(number).replace(/^(\D+)/, '$ ' + (number < 0 ? "-" : ""))
}