import {Dialog, Grid2, IconButton, Slide, Typography, useMediaQuery} from "@mui/material";
import {forwardRef, useMemo, useState} from "react";
import {Close} from "@mui/icons-material";
import {theme} from "../../Tema";
import {TEXTO} from "../ContantesColorDialogo";


export const useDialogo = ({Componente, realizado, titulo = ''}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [open, setOpen] = useState(false)
    const [datos, setDatos] = useState({})

    const abrir = (e) => {
        setDatos(e)
        setOpen(true)
    }

    const cerrar = (res) => {
        setOpen(false)
        if (res) {
            realizado()
        }

    }

    const Transition = forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    const Dialogo = () => useMemo(() => {


        return (
            <Dialog open={open} keepMounted TransitionComponent={Transition} fullWidth
                    maxWidth={'xs'}
                    scroll={'body'}
                    sx={{m: -2}}>
                <Grid2
                    container
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    size={{xs: 12, sm: 12, md: 12}}
                    sx={{p: 2, overflowX: 'hidden'}}
                >

                    <Grid2 container size={{xs: 10, sm: 10, md: 10}}
                           sx={{justifyContent: 'flex-start', px: 0, mt: -1.5}}>
                        <Typography sx={{fontWeight: 700, fontSize: 18, color: TEXTO}}>{titulo}</Typography>
                    </Grid2>

                    <Grid2 container size={{xs: 2, sm: 2, md: 2}} sx={{justifyContent: 'flex-end', pr: 0, mt: -1}}>
                        <IconButton onClick={() => cerrar()} sx={{mr: -1}}>
                            <Close/>
                        </IconButton>
                    </Grid2>

                    <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{marginTop: 1}}>
                        <Componente datos={datos} cerrar={cerrar} realizado={realizado}/>
                    </Grid2>

                </Grid2>


            </Dialog>
        )
    }, [])

    return {
        Dialogo,
        abrir,
        cerrar,
    }
}