/**************************************************
 * Nombre:       Casilla_Avatar_Titulo_Dato
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {TableCell} from "@mui/material";
import {COLORTEXTO} from "../../../Constantes";
import {formatoMonedaTabla} from "../../../Utilidades/formatoMonedaTabla";

const Casilla_Moneda = ({dato = 0, decimales = 0, dir}) => {

    return (
        <TableCell key={dato + new Date().getTime()} variant={'body'} align={dir} sx={{color: COLORTEXTO, fontSize: 14, fontWeight: 500}}>
            {formatoMonedaTabla(dato, decimales)}
        </TableCell>

    )

}
export default Casilla_Moneda