/**************************************************
 * Nombre:       Dona_Limpia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Chart from "react-apexcharts";
import {COLORES} from "../ContantesColorGraficas";

const Linea_Limpia = ({ancho, labels = [], series=[], marginTop=0, height='auto'}) => {
    const options = {
        chart: {
            id: "area-bar",
            sparkline: {
                enabled: false,
            },
            toolbar:{
                show: false
            }

        },
        labels: labels,
        stroke: {
            width: 2,
            curve: 'smooth',
        },
        colors: COLORES,
        dataLabels: {
            show: true,
            enabledOnSeries: true,
        },
        xaxis: {
            labels:{
                show: false
            },
            axisBorder:{
                show: false
            },
            axisTicks: {
                show: false,
            },

        },
        yaxis:{
            opposite: true,
        },
        legend:{
            show: false
        },
        markers:{
            size: 3
        }

    }



    return (
        <Chart
            options={options}
            series={series}
            type="line"
            width={ancho}
            height={height}
            style={{marginTop: 0, marginBottom: -20}}
        />
    )

}
export default Linea_Limpia