/**************************************************
 * Nombre:       Esqueleto_Cuerpo_Tabla
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {IconButton, Skeleton, TableCell, TableRow} from "@mui/material";
import {COLORTEXTO} from "../../../Constantes";
import {MoreVert} from "@mui/icons-material";

const Esqueleto_Cuerpo_Tabla = ({datosCabecera}) => {

    return (
        <>
            {data.map((item, index) => {
                return (
                    <TableRow key={`tr${index}`} >

                        {datosCabecera.map((item, index) => {
                            return (
                                <TableCell  key={`tsr${index}`} variant={'body'} align={item.direccion}
                                           sx={{color: COLORTEXTO, fontSize: 14, fontWeight: 500}}>
                                    <Skeleton variant="rounded" width={'100%'} height={14}/>
                                </TableCell>
                            )
                        })}

                        <TableCell padding={'normal'} variant={'body'} align={'right'}
                                   sx={{color: COLORTEXTO, fontSize: 14, width: 10}}>
                            <IconButton disabled>
                                <MoreVert/>
                            </IconButton>
                        </TableCell>


                    </TableRow>
                )
            })}

        </>

    )

}
export default Esqueleto_Cuerpo_Tabla

const data = [1, 2, 3, 4, 5]