/**************************************************
 * Nombre:       Seccion_Usuarios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2} from "@mui/material";
import {
    CASILLA_ESTADO,
    CASILLA_FECHA_DATO,
    CASILLA_TEXTO
} from "../../Modulo_Tabla_2025/Componentes/SubComponentes/obtenerCasilla";
import {useObtenerColleccionTabla2025} from "../../Servicios/BD/useObtenerColleccionTabla2025";
import {useDialogo} from "../../Modulo_Dialogos_2025/Hooks/useDialogo";
import React, {useContext, useEffect, useRef, useState} from "react";
import Tabla_Filtro from "../../Modulo_Tabla_2025/Componentes/Tabla_Filtro";
import Dialogo_Pqrs from "../Dialogos/Dialogo_Pqrs";
import {ManageSearch} from "@mui/icons-material";
import {ESTADOSARTICULO, ESTADOSPQRS} from "../../Constantes";
import {useObtenerAlto} from "../../Modulo_Graficas/Hooks/useObtenerAlto";
import Area_Limpia from "../../Modulo_Graficas/Graficas/Area_Limpia";
import Tarjeta_Valor_Titulo_Grafica from "../../Modulo_Graficas/Componentes/Tarjetas/Tarjeta_Valor_Titulo_Grafica";
import Dona_Limpia from "../../Modulo_Graficas/Graficas/Dona_Limpia";
import {arrConteoColSemanasAtras} from "../../Servicios/BD/Contadores_Arr/arrConteoColSemanasAtras";
import {arrConteoColPropiedadEstados} from "../../Servicios/BD/Contadores_Arr/arrConteoColPropiedadEstados";
import Titulo_Dos_Datos from "../../Modulo_Graficas/Componentes/Titulos/Titulo_Dos_Datos";
import {usuarioContext} from "../../App";
import {where} from "firebase/firestore";
import {contarWheres} from "../../Servicios/BD/Contadores/contarWheres";
import {arrConteoColMesesAtrasWheres} from "../../Servicios/BD/Contadores_Arr/arrConteoColMesesAtrasWheres";
import {useResponsive} from "../../Modulo_Responsive/Hooks/useResponsive";


const Seccion_Pqrs = () => {
    const {sCell} = useResponsive()
    const refe = useRef()
    const {alto} = useObtenerAlto({contenedor: refe})
    const cData = useContext(usuarioContext)
    const {
        props,
        cargarNuevamente,
    } = useObtenerColleccionTabla2025({coleccion: 'pqrs'})
    const [potencialesSemanas, setPotencialesSemanas] = useState([])
    const [potencialesEstado, setPotencialesEstado] = useState([])
    const [numUsuariosActivos, setNumUsuariosActivos] = useState(0)
    const [numUsuariosActivosMes, setNumUsuariosActivosMes] = useState([])
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Pqrs,
        titulo: 'Detalles de Pqrs',
        realizado: cargarNuevamente
    })
    const altoGraficas = sCell ? 190 : 100

    const click = (id, data) => {

        switch (id) {
            case 'detalles':
                abrir(data)
            default :
                abrir(data)
        }
    }

    useEffect(() => {

        let w = [where('estado', '==', 'Registrado')]
        contarWheres({coleccion: 'pqrs', wheres: []}).then((dox) => {
            setNumUsuariosActivos(dox.data)
        })

        arrConteoColMesesAtrasWheres({coleccion: 'pqrs', mesesAtras: 2, wheres: []}).then((dox) => {
            setNumUsuariosActivosMes(dox)
        })

        arrConteoColSemanasAtras({coleccion: 'pqrs', semanasAtras: 4}).then((arr) => {
            setPotencialesSemanas(arr)
        })

        arrConteoColPropiedadEstados({
            coleccion: 'pqrs',
            propiedad: 'estado',
            arrEstados: ESTADOSPQRS.map((e) => e.nombre)
        }).then((arr) => {
            setPotencialesEstado(arr)
        })


    }, []);
    return (
        <Grid2 container size={{xs: 12, sm: 12, md: 12}} justifyContent={'center'}>

            <Grid2
                container
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                size={{xs: 12, sm: 12, md: 12}}
                sx={{marginTop: 2.5, maxWidth: '1400px'}}
                spacing={3}
            >

                <Dialogo/>


                <Grid2 ref={refe} container size={{xs: 12, sm: 12, md: 8}}>
                    <Titulo_Dos_Datos titulo={'Pqrs'}
                                      datoUno={numUsuariosActivos}
                                      usuario={cData.usuario}
                                      datoCuatro={numUsuariosActivosMes[0] ? numUsuariosActivosMes[0]?.cantidad : 0}
                                      datoTres={numUsuariosActivosMes[1] ? numUsuariosActivosMes[1]?.cantidad : 0}/>
                </Grid2>

                <Grid2 container size={{xs: 6, sm: 3, md: 2}} sx={{height: alto}}>
                    <Tarjeta_Valor_Titulo_Grafica valor={'Estados'}
                                                  titulo={'4 estados'}
                                                  Grafica={Dona_Limpia}
                                                  height={altoGraficas}
                                                  px={2}
                                                  series={potencialesEstado.map((e) => e.cantidad)}
                                                  labels={potencialesEstado.map((e) => e.nombre)}/>
                </Grid2>


                <Grid2 container size={{xs: 6, sm: 3, md: 2}} sx={{height: alto}}>
                    <Tarjeta_Valor_Titulo_Grafica valor={'Ultimas'}
                                                  titulo={'4 Semanas'}
                                                  Grafica={Area_Limpia}
                                                  height={altoGraficas}
                                                  series={
                                                      [{
                                                          name: 'Valor',
                                                          data: potencialesSemanas.map((e) => e.cantidad)
                                                      }]
                                                  }
                                                  labels={potencialesSemanas.map((e) => e.nombre)}/>
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{marginTop: sCell ? -16 : 0}}>
                    <Tabla_Filtro
                        nombre={'Pqrs'}
                        descripcion={`${props.numeroFilas} pqrs en base de datos`}
                        filtros={filtros}
                        datosCabecera={datosCabecera}
                        {...props}
                      //  clickAgregar={abrir}
                        botonesData={botonesData}
                        clickData={click}
                        // funcionBuscar={funcionBuscar}
                        //  botones={[]}
                    />
                </Grid2>


            </Grid2>

        </Grid2>
    )

}


export default Seccion_Pqrs

const filtros = [
    {
        nombre: 'Nombre',
        direccion: 'asc',
        propiedad: 'nombre'
    },
    {
        nombre: 'Mas Reciente',
        direccion: 'desc',
        propiedad: 'fecha'
    }
]

const datosCabecera = [
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',
        fecha: 'fecha',
        tipo: CASILLA_FECHA_DATO
    },
    {
        nombre: 'Correo',
        direccion: 'left',
        propiedad: 'correo',
        estados: CASILLA_TEXTO
    },
    {
        nombre: 'Tipo',
        direccion: 'right',
        propiedad: 'tipo',
    }, {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',
        tipo: CASILLA_ESTADO,
        estados: ESTADOSPQRS
    },


]

const botonesData = [
    {
        nombre: 'Detalles',
        id: 'detalles',
        icono: ManageSearch
    }
]
