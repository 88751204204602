/**************************************************
 * Nombre:       Casilla_Avatar_Titulo_Dato
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, TableCell, Typography} from "@mui/material";
import {COLORTEXTO} from "../../../Constantes";
import {formatoFechaTabla} from "../../../Utilidades/formatoFechaTabla";

const Casilla_Fecha_Datos = ({fecha, datos, propiedades = [], dir}) => {

    return (
        <TableCell key={fecha} variant={'body'} align={dir}
                   sx={{color: COLORTEXTO, fontWeight: 600, fontSize: 14, minWidth: 160}}>
            <Grid2 container size={12} alignItems={'center'}>


                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                        <Typography sx={{fontSize: 12}}>
                            {formatoFechaTabla(fecha)}
                        </Typography>
                    </Grid2>
                    <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                        <Typography sx={{fontSize: 14, fontWeight: 600}}>
                            {propiedades.map((it, index) => {
                                return (
                                    <span>{`${datos[it]}  `}</span>
                                )
                            })}
                        </Typography>
                    </Grid2>
                </Grid2>

            </Grid2>

        </TableCell>

    )

}
export default Casilla_Fecha_Datos