import {obtenerFechasIniFInMesesAtras} from "./Funciones/obtenerFechasIniFInMesesAtras";
import {contarWheres} from "../Contadores/contarWheres";
import {where} from "firebase/firestore";
import dayjs from "dayjs";
import 'dayjs/locale/es'


export const arrConteoColMesesAtrasWheres = async ({coleccion, mesesAtras = 1, wheres = []}) => {
    dayjs.locale('es')
    // obtener arr de timestamp inicio mes, fin mes
    let arrFechasInicioFin = obtenerFechasIniFInMesesAtras(mesesAtras)

    let arrPromesas = []

    for (let i = 0; i < arrFechasInicioFin.length; i++) {
        arrPromesas.push(docmentosFecha(arrFechasInicioFin[i], coleccion, wheres))
    }

    let arr = await Promise.all(arrPromesas)

    return arr.reverse()

}

const docmentosFecha = async (fechas, coleccion, wrs) => {
    dayjs.locale('es')
    let wheres = [where('fecha', '>=', fechas.fechaInicio), where('fecha', '<=', fechas.fechaFin), ...wrs]

    let result = await contarWheres({coleccion: coleccion, wheres: wheres})

    return {
        nombre: dayjs(fechas.fechaInicio).format('MMMM').charAt(0).toUpperCase() + dayjs(fechas.fechaInicio).format('MMMM').slice(1),
        cantidad: result.data
    }

}