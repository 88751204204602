/**************************************************
 * Nombre:       BarraInferior
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid2, TablePagination, useMediaQuery} from "@mui/material";
import {Download} from "@mui/icons-material";
import {theme} from "../../../../Tema";

const BarraInferior = ({
                           numeroFilas,
                           filasPagina,
                           page,
                           cambiarPagina,
                           filasPaginaCambia,
                            botones
                       }) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{alignItems: 'center'}} >

            <Grid2 container size={{xs: 12, sm: 6, md: 6}}>
                {botones.map((item, index) => {
                    return (
                        <item />
                    )
                })}

            </Grid2>

            <Grid2 container size={{xs: 12, sm: 6, md: 6}}
                   sx={{justifyContent: sCell ? 'center' : 'flex-end',}}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={numeroFilas}
                    rowsPerPage={filasPagina}
                    page={page}
                    onPageChange={cambiarPagina}
                    onRowsPerPageChange={filasPaginaCambia}
                    labelRowsPerPage={'Filas'}
                    sx={{overflowX: 'hidden'}}
                />
            </Grid2>

        </Grid2>
    )

}
export default BarraInferior