/**************************************************
 * Nombre:       Tarjeta_Valor_Titulo_Grafica
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Typography} from "@mui/material";
import {BORDER, SOMBRA, TEXTO, TEXTOCLARO} from "../../ContantesColorGraficas";
import {useObtenerAncho} from "../../Hooks/useObtenerAncho";
import {useRef} from "react";
import Titulo_Dato from "./Componentes/Titulo_Dato";
import {convertirSegundosTiempo} from "../../../Modulo_Analitics/Utilidades/convertirSegundosTiempo";

const Tarjeta_Titulo_Subtitulo_3_Datos_Grafica_Larga = ({
                                                            subtitulo,
                                                            titulo,
                                                            Grafica,
                                                            labels,
                                                            series,
                                                            height = 'auto',
                                                            marginTop = 0,
                                                            datos

                                                        }) => {
    const refe = useRef()
    const {ancho} = useObtenerAncho({contenedor: refe})


    return (
        <Grid2
            container
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            size={{xs: 12, sm: 12, md: 12}}
            sx={{
                backgroundColor: '#fff',
                borderRadius: 2,
                border: BORDER,
                boxShadow: SOMBRA,
                p: 2,
                height: '100%',
            }}
        >

            <Grid2 container size={{xs: 12, sm: 4, md: 4}}>
                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <Typography
                        sx={{color: TEXTO, fontSize: 18, fontWeight: 700, lineHeight: 1.1}}>{titulo}</Typography>
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{marginTop: 1}}>
                    <Typography sx={{
                        color: TEXTOCLARO,
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: 1.1
                    }}>{subtitulo}</Typography>
                </Grid2>
            </Grid2>

            <Grid2
                container
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                size={{xs: 12, sm: 8, md: 8}} sx={{marginTop: {xs: 2, sm: 0}}}
                spacing={{xs: 2, sm: 0}}
            >

                <Grid2 container size={{xs: 6, sm: 4, md: 4}}>
                    <Titulo_Dato titulo={'Total Usuarios'} dato={datos?.totalUsuarios}/>
                </Grid2>

                <Grid2 container size={{xs: 6, sm: 4, md: 4}}>
                    <Titulo_Dato titulo={'Usuarios Nuevos'} dato={datos?.usuariosNuevos}/>
                </Grid2>

                <Grid2 container size={{xs: 6, sm: 4, md: 4}}>
                    <Titulo_Dato titulo={'Tiempo promedio'} dato={convertirSegundosTiempo(datos?.tiempoPromedio)}/>
                </Grid2>


            </Grid2>


            <Grid2 ref={refe} container size={{xs: 12, sm: 12, md: 12}}
                   sx={{px: 0, py: 0, justifyContent: 'center'}}>

                <Grafica ancho={ancho} labels={labels} series={series} height={height} marginTop={marginTop}/>

            </Grid2>

        </Grid2>
    )

}
export default Tarjeta_Titulo_Subtitulo_3_Datos_Grafica_Larga