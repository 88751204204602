/**************************************************
 * Nombre:       BarraSuperior
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid2, InputAdornment, Skeleton, TablePagination, TextField, useMediaQuery} from "@mui/material";
import {Add, ManageSearch, Search} from "@mui/icons-material";
import {theme} from "../../../../Tema";
import {useState} from "react";

const BarraSuperior = (
    {
        numeroFilas,
        filasPagina,
        page,
        cambiarPagina,
        filasPaginaCambia,
        funcionBuscar,
        clickAgregar,
        cargando
    }
) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [palabra, setPalabra] = useState('')

    return (
        <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{alignItems: 'center'}}>

            <Grid2 container size={{xs: 12, sm: 2, md: 3}} sx={{pr: 2}}>
                {cargando ?

                    <Skeleton variant="rounded" width={'80%'} height={24}/>
                    :
                    <>
                        {clickAgregar !== null &&
                            <Button
                                onClick={() => clickAgregar()}
                                variant={'contained'} startIcon={<Add/>}>Agregar</Button>
                        }
                    </>

                }
            </Grid2>

            <Grid2 container size={{xs: 12, sm: 4, md: 4}} sx={{marginTop: sCell ? 3 : 0}}>
                {funcionBuscar &&
                    <TextField
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <ManageSearch sx={{fill: '#00000040'}}/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            disabled={!palabra}
                                            onClick={(e) => funcionBuscar(palabra)}
                                            variant={'contained'} sx={{fontSize: 12}}>
                                            <Search/>

                                        </Button>
                                    </InputAdornment>
                                ),

                                disableUnderline: true
                            },
                            inputLabel: {
                                style: {
                                    marginLeft: -6
                                }
                            }
                        }}

                        value={palabra}
                        onChange={(e) => setPalabra(e.target.value)}
                        variant={'filled'} label={'Barra de busqueda'} size={'small'}/>
                }
            </Grid2>

            <Grid2 container size={{xs: 12, sm: 6, md: 5}} sx={{justifyContent: sCell ? 'center' : 'flex-end'}}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={numeroFilas}
                    rowsPerPage={filasPagina}
                    page={page}
                    onPageChange={cambiarPagina}
                    onRowsPerPageChange={filasPaginaCambia}
                    labelRowsPerPage={'Filas'}
                    sx={{overflowX: 'hidden'}}
                />
            </Grid2>

        </Grid2>
    )

}
export default BarraSuperior