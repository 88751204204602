/**************************************************
 * Nombre:       Casilla_Avatar_Titulo_Dato
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, TableCell, Typography} from "@mui/material";
import {COLORTEXTO} from "../../../Constantes";

const Casilla_Estado = ({estado = '', arrEstados = [], dir}) => {

    let index = arrEstados.findIndex((e) => e.nombre === estado)

    return (
        <TableCell key={estado+new Date().getTime()} variant={'body'} align={dir} sx={{color: COLORTEXTO, fontSize: 14}}>
            <Grid2 container size={{xs: 12, sm: 12, md: 12}} justifyContent={'center'}
                   sx={{

                       backgroundColor: index > -1 ? arrEstados[index].color + 20 : '#5d87ff10', borderRadius: 1.5

                   }}>

                <Typography
                    sx={{
                        textAlign: 'center',
                        fontWeight: 700,
                        color: index > -1 ? arrEstados[index].color : '#5d87ff',
                        fontSize: 14
                    }}>{estado}</Typography>

            </Grid2>
        </TableCell>

    )

}
export default Casilla_Estado