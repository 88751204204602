/**************************************************
 * Nombre:       Tabla_Prueba
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Paper, Table, TableBody, TableContainer, TableHead} from "@mui/material";
import BarraSuperior from "../ComponentesTabla/BarraSuperior";
import BarraInferior from "../ComponentesTabla/BarraInferior";
import CabeceraTabla from "../ComponentesTabla/CabeceraTabla";
import Esqueleto_Cuerpo_Tabla from "../Esqueletos/Esqueleto_Cuerpo_Tabla";
import CuerpoTabla from "../ComponentesTabla/CuerpoTabla";

const Tabla_Normal = ({
                          data = [],
                          datosCabecera = [],
                          numeroFilas,
                          filasPagina,
                          page,
                          cambiarPagina,
                          filasPaginaCambia,
                          estado,
                          setEstado,
                          funcionBuscar,
                          cargando,
                          botones,
                          clickAgregar,
                          botonesData,
                          clickData

                      }) => {


    return (

        <TableContainer component={Paper} sx={{boxShadow: 0}}>

            <Grid2 container size={{xs: 12, sm: 12, md: 12}}
                   sx={{justifyContent: 'flex-start', alignItems: 'center', position: 'sticky', left: '0%'}}>
                <BarraSuperior
                    numeroFilas={numeroFilas}
                    filasPagina={filasPagina}
                    page={page}
                    cambiarPagina={cambiarPagina}
                    filasPaginaCambia={filasPaginaCambia}
                    funcionBuscar={funcionBuscar}
                    clickAgregar={clickAgregar}
                    cargando={cargando}

                />
            </Grid2>


            <Table aria-label="simple normal"
                   stickyHeader
            >
                <TableHead>
                    <CabeceraTabla datosCabecera={datosCabecera} estado={estado} setEstado={setEstado}/>
                </TableHead>

                <TableBody sx={{minHeight: 50}}>


                    {cargando ?
                        <Esqueleto_Cuerpo_Tabla datosCabecera={datosCabecera}/>
                        :
                        <CuerpoTabla data={data} datosCabecera={datosCabecera} botonesData={botonesData} clickData={clickData}/>
                    }

                </TableBody>
            </Table>

            <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginTop: 2,
                position: 'sticky', left: '0%'
            }}>

                <BarraInferior numeroFilas={numeroFilas}
                               filasPagina={filasPagina}
                               page={page}
                               cambiarPagina={cambiarPagina}
                               filasPaginaCambia={filasPaginaCambia}
                               funcionBuscar={funcionBuscar}
                               clickAgregar={clickAgregar}
                               cargando={cargando}
                               botones={botones}/>

            </Grid2>

        </TableContainer>

    )

}
export default Tabla_Normal



