/**************************************************
 * Nombre:       FilaDatos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import Casilla_Texto from "./CasillasDatos/Casilla_Texto";
import {obtenerCasilla} from "./obtenerCasilla";

const FilaDatos = ({item, datosCabecera}) => {

    return (
        <>

            {datosCabecera.map((it, index) => {
                return obtenerCasilla({cabecera: it, datos:item})
            })}

            {/*}  <Casilla_Avatar_Titulo_Dato titulo={item.nombre} dato={item.dato}/>



            <Casilla_Estado/>

            <Casilla_Moneda/> */}

        </>
    )

}
export default FilaDatos