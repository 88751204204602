/**************************************************
 * Nombre:       Titulo_Dos_Datos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Avatar, Grid2, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import hombre from '../../RecursosGraficas/hombre_computador.svg'
import img_usuario from '../../RecursosGraficas/usuario_defecto.jpg'
import Valor_Titulo_Diferencia from "./SubComponentes/Valor_Titulo_Diferencia";
import {TEXTO} from "../../ContantesColorGraficas";
import {useMediaQuery} from "@mui/system";

const Titulo_Dos_Datos = ({titulo, usuario = {}, datoUno = 0, datoDos = 0, datoTres = 0, datoCuatro = 0}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))


    return (
        <Grid2
            container
            justifyContent={'flex-start'}
            alignItems={'flex-end'}
            size={{xs: 12, sm: 12, md: 12}}
            sx={{
                backgroundColor: theme.palette.primary.main + 10,
                borderRadius: 2,
            }}
        >

            <Grid2 container size={{xs: 12, sm: 7, md: 7}} sx={{py: 4, pl: 4, pr: 4}}>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}} alignItems={'center'}>

                    <Grid2 container size={{xs: 2, sm: 1, md: 1}}>
                        <Avatar src={usuario?.img ? usuario.img : img_usuario}/>
                    </Grid2>
                    <Grid2 container size={{xs: 10, sm: 10, md: 10}} sx={{pl: sCell ? 0 : 2, }}>
                        <Typography sx={{fontWeight: 700, fontSize: 18, color: TEXTO, lineHeight: 1.1}}>
                            {`Bienvenido ${usuario?.nombre} ${usuario?.apellido}!`}
                        </Typography>
                    </Grid2>

                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{marginTop: 3}}>

                    <Grid2 container size={{xs: 6, sm: 5, md: 5}}>
                        <Valor_Titulo_Diferencia valorActual={datoUno} valorAnterior={datoDos}
                                                 titulo={`${titulo} Total`}/>
                    </Grid2>

                    <Grid2 container size={{xs: 6, sm: 5, md: 5}}>
                        <Valor_Titulo_Diferencia valorActual={datoTres} valorAnterior={datoCuatro}
                                                 titulo={`${titulo} Mes`}/>
                    </Grid2>

                </Grid2>


            </Grid2>

            <Grid2 container size={{xs: 12, sm: 5, md: 5}}
                   sx={{
                       justifyContent: {xs: 'center', sm: 'flex-start'},
                       alignItems: 'flex-end',
                       marginTop: {xs: -3, sm: 0}
                   }}>
                <img src={hombre} width={sCell ? '90%' : '100%'} height={'auto'}/>
            </Grid2>

        </Grid2>
    )

}
export default Titulo_Dos_Datos