/**************************************************
 * Nombre:       CuerpoTabla
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import FilaDatos from "../FilaDatos";
import {Button, Grid2, IconButton, Popover, TableCell, TableRow} from "@mui/material";
import {COLORTEXTO} from "../../../Constantes";
import {MoreVert} from "@mui/icons-material";
import {useState} from "react";
import {TEXTO} from "../../../../Modulo_Dashboard/ContantesColor";

const CuerpoTabla = ({data, datosCabecera, botonesData, clickData}) => {
    const [elemetoPadre, setElementoPadre] = useState(null)
    const [open, setOpen] = useState(false)
    const [elegido,setElegido] = useState()

    const abrir = (e, it) => {
        setElementoPadre(e.target)
        setElegido(it)
        setOpen(true)
    }

    const cerrar = () => {
        setElementoPadre(null)
        setOpen(false)
    }

    return (
        <>
            {data.map((item, index) => {
                return (
                    <TableRow key={`ewr${index}`} >

                        <FilaDatos key={`asde${index}`} item={item} datosCabecera={datosCabecera}/>

                        <TableCell padding={'normal'} variant={'body'} align={'right'}
                                   sx={{color: COLORTEXTO, fontSize: 14, width: 10}}>
                            <IconButton onClick={(e) => abrir(e, item)}>
                                <MoreVert/>
                            </IconButton>
                        </TableCell>

                        {/*Popover*/}

                        <Popover
                            open={open}
                            anchorEl={elemetoPadre}
                            onClose={() => cerrar()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            slotProps={{
                                paper: {
                                    sx: {
                                        boxShadow: '0px 8px 12px 0px #00000014',
                                        border: 1,
                                        borderColor: 'rgba(143,173,213,0.31)',
                                        borderRadius: 2
                                    }

                                }
                            }}
                        >
                            <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{p: 2}}>


                                {botonesData.map((it, index) => {
                                    return (
                                        <Grid2 key={`er${index}`} container size={{xs: 12, sm: 12, md: 12}}>
                                            <Button
                                                onClick={() => {
                                                    clickData(it.id, elegido)
                                                    setOpen(false)
                                                }}
                                                variant={'text'} sx={{color: TEXTO, px: 2}} startIcon={<it.icono/>}>
                                                {it.nombre}
                                            </Button>
                                        </Grid2>

                                    )
                                })}

                            </Grid2>


                        </Popover>

                    </TableRow>
                )
            })}


        </>

    )

}
export default CuerpoTabla