/**************************************************
 * Nombre:       Tarjeta_Valor_Titulo_Grafica
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Typography} from "@mui/material";
import {BORDER, SOMBRA, TEXTOCLARO} from "../../ContantesColorGraficas";
import {useObtenerAncho} from "../../Hooks/useObtenerAncho";
import {useRef} from "react";
import {COLORTEXTO} from "../../../Modulo_Tabla_2025/Constantes";

const Tarjeta_Valor_Titulo_Grafica = ({valor, titulo, Grafica, labels, series, height = 'auto', marginTop = 0, px=0, maxHeight={xs: 210, sm: 500}}) => {
    const refe = useRef()
    const {ancho} = useObtenerAncho({contenedor: refe})


    return (
        <Grid2
            container
            justifyContent={'flex-start'}
            alignItems={'center'}
            size={{xs: 12, sm: 12, md: 12}}
            sx={{
                backgroundColor: '#fff',
                borderRadius: 2,
                border: BORDER,
                boxShadow: SOMBRA,
                p: 2,
                maxHeight: maxHeight,
                height: '100%',
            }}
        >


            <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                <Typography sx={{color: COLORTEXTO, fontWeight: 700, fontSize: 18}}>{valor}</Typography>
            </Grid2>

            <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                <Typography sx={{color: TEXTOCLARO, fontSize: 14, fontWeight: 500}}>{titulo}</Typography>
            </Grid2>

            <Grid2 ref={refe} container size={{xs: 12, sm: 12, md: 12}}
                   sx={{px: px, py: 2, justifyContent: 'center', height: height, alignItems: 'flex-end', marginTop: {xs: maxHeight === 'auto' ? -4 : -7, sm: 0}  }}>
                <Grafica ancho={ancho} labels={labels} series={series} height={height} marginTop={marginTop}/>
            </Grid2>

        </Grid2>
    )

}
export default Tarjeta_Valor_Titulo_Grafica