/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Alert, Grid2, Slide, Snackbar} from "@mui/material";
import DashboardBase from "../Modulo_Dashboard/Componentes/DashboardBase";
import React, {createContext, useContext, useEffect, useState} from "react";
import {
    AccountCircle,
    AccountCircleOutlined, AddAlertOutlined, AllInboxOutlined, ArticleOutlined,
    DashboardOutlined as DashFill,
    Notifications,
    PersonAddAltOutlined
} from "@mui/icons-material";
import {funSalir} from "../Servicios/Auth/funSalir";
import {useEscucharNotificacion} from "../Modulo_Notificaciones/Hooks/useEscucharNotificacion";
import {useGetToken} from "../Modulo_Notificaciones/Hooks/useGetToken";
import {usuarioContext} from "../App";
import {obtenerCol} from "../Servicios/BD/obtenerCol";
import {agregarItemArrayDoc} from "../Servicios/BD/agregarItemArrayDoc";
import Seccion_Dashboard from "./Secciones/Seccion_Dashboard";
import Seccion_Pqrs from "./Secciones/Seccion_Pqrs";
import Seccion_Articulos from "./Secciones/Seccion_Articulos";


export const contexBarra = createContext()
const Dashboard = () => {
    const [notificaciones, setNotificaciones] = useState([])
    const [nuevasNotificaciones, setNuevasNotificaciones] = useState(0)
    const [historial, setHistorial] = useState([])
    const [nuevoHistorial, setNuevoHistorial] = useState(0)
    const {usuario} = useContext(usuarioContext)
    const {token} = useGetToken()
    const {Notificador} = useEscucharNotificacion()
    const [open, setOpen] = useState(false)
    const salir = () => {
        funSalir()
    }

    const valoresBarra = {
        notificaciones,
        nuevasNotificaciones,
        historial,
        nuevoHistorial,
        funSalir: salir

    }


    const {Provider} = contexBarra;


    useEffect(() => {


        if (usuario && usuario.id && token) {
            obtenerCol('tokens', 'tokens').then((dox) => {
                if (dox.res && dox.data) {
                    let arrTokens = dox.data
                    let index = arrTokens.findIndex((e) => e === token)

                    if (index >= 0) {
                        setOpen(true)
                    } else {
                        agregarItemArrayDoc('tokens', 'tokens', 'arrTokens', token).then((des) => {
                            if (des.res) {
                                // console.log('Token actualizado', token)
                                setOpen(true)
                            }
                        })
                    }
                }
            })
        }

    }, [usuario, token]);
    return (
        <Grid2
            container
            size={12}
        >

            <Notificador/>

            <Provider value={valoresBarra}>
                <DashboardBase secciones={secciones} seccionesPerfil={seccionesPerfil} seccionesID={seccionesID}/>
            </Provider>

            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                TransitionComponent={Slide}
                //action={action}
                role={'alert'}
                disableWindowBlurListener={true}

            >
                <Alert
                    icon={<Notifications fontSize="inherit"/>}
                    onClose={() => setOpen(false)}
                    severity={'secondary'}
                    variant={'filled'}
                    sx={{width: '100%'}}
                >
                    {'Notificaciones Encendidas'}
                </Alert>
            </Snackbar>

        </Grid2>
    )

}
export default Dashboard

const secciones = [
    {
        nombre: 'Dashboard',
        icono: DashFill,
        camino: '/Dashboard',
        categoria: 'Pagina',
        Componente: <Seccion_Dashboard/>
    },
    {
        nombre: 'Articulos',
        icono: ArticleOutlined,
        camino: '/Articulos',
        categoria: 'Pagina',
        Componente: <Seccion_Articulos/>
    },
    {
        nombre: 'Pqrs',
        icono: AddAlertOutlined,
        camino: '/Pqrs',
        categoria: 'Pagina',
        Componente: <Seccion_Pqrs/>
    },


]

const seccionesID = [
    {
        nombre: 'Dashboard',
        icono: DashFill,
        camino: '*',
        Componente: <Seccion_Dashboard/>
    },

]

const seccionesPerfil = []

const notificaciones = {}