export const TEXTO = '#2a3547'
export const TEXTOCLARO = '#5a6a85'

export const ARRIBA = '#13deb9'

export const ABAJO = '#fa896b'

export const BORDER = '0.5px solid #EAEAEA'
export const SOMBRA = '0px 8px 12px 0px #00000014'

export const COLORES = ['#FF6623','#000000', '#FF6623','#4A426E', '#4A426E']




