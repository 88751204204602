/**************************************************
 * Nombre:       Dona_Limpia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import Chart from "react-apexcharts";
import {COLORES} from "../ContantesColorGraficas";

const Barra_Horizontal_Limpia = ({ancho, labels = [], series = [], marginTop = 0, height = 'auto'}) => {
    const options = {
        chart: {
            id: "area-bar",
            type: 'bar',
            sparkline: {
                enabled: false,
            },
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                horizontal: true
            }
        },
        labels: labels,
        colors: COLORES,
        dataLabels: {
            show: true,
            enabledOnSeries: true,
        },
        yaxis: {
            labels: {
                show: true
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },

        },
        xaxis: {
            axisBorder: {
                border: false
            },
            axisTicks: {
                show: false,
            },
        },

        legend: {
            show: false
        },
        markers: {
            size: 3
        }

    }


    return (
        <Chart
            options={options}
            series={series}
            type="bar"
            width={ancho}
            height={height}
            style={{marginTop: 0, marginBottom: 0}}
        />
    )

}
export default Barra_Horizontal_Limpia