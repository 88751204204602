/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircleOutlined, ApartmentOutlined,
    BadgeOutlined, CakeOutlined, Flag, FlagOutlined, HomeOutlined,
    HomeWorkOutlined,
    MailOutline,
    PhoneAndroidOutlined,
    RecentActorsOutlined, Title
} from "@mui/icons-material";
import {useEffect, useState} from "react";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOSARTICULO} from "../../Constantes";
import IngresoFecha from "../../Modulo_formularios/Componentes/IngresoFecha";
import {obtenerDepartamentos} from "../../Modulo_Graficas/Graficas/Componentes/Mapa/Funciones/obtenerDepartamentos";
import {obtenerCiudades} from "../../Modulo_Graficas/Graficas/Componentes/Mapa/Funciones/obtenerCiudades";
import IngresoFile from "../../Modulo_formularios/Componentes/IngresoFile";
import IngresoTextoEstilo from "../../Modulo_formularios/Componentes/IngresoTextoEstilo";

const Formulario_Articulo = ({...props}) => {
    const [departamento, setDepartamento] = useState('')


    useEffect(() => {

        let dep = props.props.getValues('departamento')
        setDepartamento(dep)

    }, [props.props.watch('departamento')]);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid2
                container
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                size={{xs: 12, sm: 12, md: 12}}
                spacing={2}
            >

                <Grid2 container size={{xs: 12, sm: 12, md: 6}}>
                    <IngresoSelect Icono={FlagOutlined} nombre={'Estado'} dato={'estado'} {...props}
                                 opciones={ESTADOSARTICULO.map((it) => it.nombre)}
                    />
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <Typography sx={{fontSize: 14, fontWeight: 600}}>Datos Home</Typography>
                </Grid2>


                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <IngresoTexto Icono={Title} nombre={'Nombre'} dato={'nombre'} {...props}
                                  requerido={'El Nombre es requerido'}
                    />
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <IngresoTexto  nombre={'Descripcion'} dato={'descripcion'} {...props}
                                  requerido={'La Descripcion es requerido'} lineas={8}
                    />
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <Typography sx={{fontSize: 14, fontWeight: 600}}>Pdf del articulo</Typography>
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <IngresoFile   dato={'link'} {...props}
                    />
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <Typography sx={{fontSize: 14, fontWeight: 600}}>Cuerpo del articulo</Typography>
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <IngresoTextoEstilo   dato={'textoEstilo'} {...props}
                    />
                </Grid2>

            </Grid2>

        </form>

    )

}
export default Formulario_Articulo