/**************************************************
 * Nombre:       Titulo_Filtro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, MenuItem, TextField, Typography, useMediaQuery} from "@mui/material";
import {COLORTEXTO, COLORTEXTOSUAVE} from "../../../Constantes";
import {theme} from "../../../../Tema";

const Titulo_Filtro = ({nombre, descripcion, filtros, procesarFiltro, filtroEscogido}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))


    return (
        <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{alignItems: 'flex-end'}}>

            <Grid2 container size={{xs: 12, sm: 9, md: 9}}>
                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <Typography sx={{color: COLORTEXTO, fontWeight: 700, fontSize: 18}}>{nombre}</Typography>
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                    <Typography sx={{color: COLORTEXTOSUAVE, fontWeight: 500, fontSize: 14}}>{descripcion}</Typography>
                </Grid2>
            </Grid2>

            <Grid2 container size={{xs: 12, sm: 3, md: 3}} sx={{marginTop: sCell ? 3 : 0}}>

                <TextField size={'small'} variant={'outlined'}
                           slotProps={{
                               inputLabel: {
                                   style: {
                                       marginLeft: -6,
                                       fontSize: 14
                                   }
                               },
                               input: {
                                   style: {
                                       color: COLORTEXTO,
                                       fontWeight: 600,
                                       fontSize: 14
                                   }
                               }
                           }}
                           onChange={(e) => procesarFiltro(e.target.value)}
                           value={filtroEscogido.nombre}
                           select
                           defaultValue={''}
                           label={'Filtro'}>


                    {filtros.map((c, i) => (
                        <MenuItem key={`c-${i}`} value={c.nombre}
                                  sx={{fontSize: 14}}>
                            {c.nombre}
                        </MenuItem>
                    ))}

                </TextField>

            </Grid2>
        </Grid2>
    )

}
export default Titulo_Filtro