import {createTheme} from '@mui/material/styles';


export const theme = createTheme({

    palette: {
        primary: {
            main: "#FF6623",
            light: '#fff',
        },
        secondary: {
            main: "#FB284E",
        },
        info: {
            main: "#2a3547",
        },
        success: {
            main: "#9E9DA2"
        },
        action: {
            main: "#FFF"
        },
        warning: {
            main: '#fa896b'
        }

    },
    typography: {
        fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
    },
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    transition: "all .2s ease-in-out"
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                variant: "filled",
                size: "medium"
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 6
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    paddingLeft: 10
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 6
                }
            }
        },
        MuiButtonBase: {
            variants: [
                {
                    props: {variant: 'efecto'},
                    style: {
                        transition: 'all .1s ease-in-out',
                        borderRadius: 10,
                        '&: hover': {
                            marginTop: -6,
                            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",

                        }
                    },
                },


            ],

        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 8
                }
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",

            },
            styleOverrides: {
                root: {
                    borderRadius: 6,
                    fontWeight: 600,
                    textTransform: "none",
                    color: '#fff'

                },

            }
        }


    }


})
