import {Backdrop, CircularProgress, Fade, Grid, Typography} from "@mui/material";
import {useState} from "react";
import './estilos.css'

export const useLoaders = ({logo}) => {
    const [open, setOpen] = useState(false)
    const [proceso, setProceso] = useState('')
    const [detener,setDetener] = useState(false)


    const abrir = (e) => {
        setProceso(e)
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)
        setDetener(true)
    }

    const Cargador = () => {

        return (
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={open}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                        <img src={logo} width={140} height={'auto'} className={ detener ? '' : 'cargando'}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >


                            <Grid item container lg={4} sm={6} xs={8} sx={{justifyContent: 'center'}}>
                                <Typography sx={{
                                    color: '#fff',
                                    marginTop: -0.5,
                                    pr: detener ? 0 : 2,
                                    textAlign: 'center'
                                }}>{detener ? 'Realizado' : proceso}</Typography>
                                {!detener &&
                                <CircularProgress size={proceso ? 20 : 0}/> }
                            </Grid>


                        </Grid>
                    </Grid>


                </Grid>

            </Backdrop>
        )
    }

    return {
        Cargador,
        abrirCargador: abrir,
        cerrarCargador: cerrar,
        setDetener: setDetener

    }
}