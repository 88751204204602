
import axios from 'axios'
import {URLAPIANALYTICS} from "../Constantes";
export const obtenerBarrerAnalitycs = () => {
    return new Promise(resolve => {
        axios.get(URLAPIANALYTICS, {})
            .then(function (response) {
                return resolve({res: true, data: response.data})
            })
            .catch(function (error) {
                return resolve({res: false, data: error.message})
            });
    })
}