export const ESTADOSARTICULO = [

    {
        nombre: 'Publicado',
        color: '#00a23a'
    },
    {
        nombre: 'Archivado',
        color: '#808080'
    },
]

export const ESTADOSPQRS = [
    {
        nombre: 'Nueva',
        color: '#FF6623'
    },
    {
        nombre: 'Atendida',
        color: '#00a23a'
    },
    {
        nombre: 'Archivado',
        color: '#808080'
    },
]

export const TIPOSPQRS = ['Peticion', 'Queja', 'Denuncia', 'Reclamo', 'Sugerencia']