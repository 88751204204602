import axios from "axios";
import {obtenerBarrerAnalitycs} from "./obtenerBarrerAnalitycs";
import {PROPIEDADNUM, TOTALUSUARIOS} from "../Constantes";

export const arrDatosYear = async ({year = 2024, dato = TOTALUSUARIOS}) => {

    let barrer = await obtenerBarrerAnalitycs()

    let data = JSON.stringify({
        "dimensions": [
            {
                "name": 'month',

            }
        ],
        "metrics": [
            {
                "name": TOTALUSUARIOS
            }
        ],
        "dateRanges":
            {
                "startDate": `${year}-01-01`,
                "endDate": `${year}-12-31`
            }
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://analyticsdata.googleapis.com/v1beta/properties/${PROPIEDADNUM}:runReport`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${barrer.data}`,
        },
        data: data
    };

    let result = await axios.request(config)

    let arrDatos = []

    if (result.status === 200 && result.data.rows) {
        let data = result.data.rows[0]
        let meses = data.dimensionValues
        let valores = data.metricValues


        for (let i = 1; i < 13; i++) {
            let index = meses.findIndex((e) => parseInt(e.value) === i)

            let obj = {}
            obj.mes = i
            if (index > -1) {
                obj.valor = parseInt(valores[index].value)
            } else {
                obj.valor = 0
            }

             arrDatos.push(obj)
        }

    }



    return arrDatos

}