export const diferenciaPorcentual = (valorActual, valorAnterior) => {

    if (valorAnterior === valorActual){
        return  0.00
    }

    if (valorAnterior < 1) {
        valorAnterior = 1
    }



    let diferencia = valorActual - valorAnterior
    // que porcentaje representa la diferencia con respecto al primer valor
    let porsentaje = (100 * diferencia) / valorAnterior

    return porsentaje.toFixed(2)
}