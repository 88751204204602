export const URLAPIANALYTICS = 'https://internationallogisticsmd.com/notis/analytics.php'

export const PROPIEDADNUM = '401672176'

export const MESES = ['Ene', 'Feb', 'Mar', 'Abr', "May", 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

export const TOTALUSUARIOS = 'totalUsers'

export const USUARIOSNUEVOS = 'newUsers'

export const TIEMPOPROMEDIONAVEGACION = 'averageSessionDuration'

export const USUARIOSACTIVOS = 'activeUsers'


export const DIMENCIONORIGEN = 'source'

export const DIMENCIONORIGENCANALES = 'sessionPrimaryChannelGroup'

export const DIMENCIONNOMBREPAGINA = 'pageTitle'






