/**************************************************
 * Nombre:       Dialogo_Despacho
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid2} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Pqrs from "../Formularios/Formulario_Pqrs";
import {useAvisos} from "../../Modulo_Avisos/Hooks/useAvisos";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {Delete} from "@mui/icons-material";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";

const Dialogo_Pqrs = ({datos, cerrar, realizado}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Avisos, nuevoAviso, terminar} = useAvisos({funcionUno: cerrar, funcionDos: realizado})
    const {Cargador, abrirCargador, setDetener} = useLoaders({logo: logo})


    const subirDatos = async () => {

        let entidad = await obtenerEntidad()
        abrirCargador('Guardando Datos')

        let result = await guardarDoc('pqrs', entidad)
        nuevoAviso({aviso: 'Datos acutalizados'})
        if (result.res) {
            setTimeout(() => {
                terminar({aviso: 'Datos guardados con exito'})
            }, 500)
        }


    }

    const borrar = async () => {
        abrirCargador('Borrando Datos')
        let borrado = await borrarDoc('pqrs', datos.id)
        terminar({aviso: 'Pqrs borrado'})
    }

    return (
        <Grid2
            container
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            size={{xs: 12, sm: 12, md: 12}}
            sx={{p: 1}}
        >

            <Avisos/>

            <Cargador/>

            <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                <Formulario_Pqrs props={props}/>
            </Grid2>

            {datos && datos.img &&
                <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{justifyContent: 'center', marginTop: 4}}>
                    <Zoom>
                        <img src={datos.img} width={'100%'} height={'auto'}/>
                    </Zoom>
                </Grid2>
            }

            <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    onClick={() => subirDatos()}
                >Guardar Datos</Button>
            </Grid2>

            {datos && datos.id &&
                <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{marginTop: 2}}>
                    <Button
                        onClick={() => borrar()}
                        variant={'text'} color={'success'} startIcon={<Delete/>} sx={{color: '#00000050'}}>Borrar
                        Pqrs</Button>
                </Grid2>
            }

        </Grid2>
    )

}
export default Dialogo_Pqrs