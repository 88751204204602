import axios from "axios";
import {obtenerBarrerAnalitycs} from "./obtenerBarrerAnalitycs";
import {DIMENCIONORIGEN, PROPIEDADNUM, TOTALUSUARIOS} from "../Constantes";

export const obtenerDatoDimencionTotalDesdeFecha = async ({
                                                              fecha = '2024-01-01',
                                                              dato = TOTALUSUARIOS,
                                                              dimencion = DIMENCIONORIGEN,
                                                              filtros = [],
                                                              noContenga = []
                                                          }) => {


    let barrer = await obtenerBarrerAnalitycs()


    let data = {
        "dimensions": [
            {
                "name": dimencion
            }
        ],
        "metrics": [
            {
                "name": dato
            }
        ],
        "dateRanges": [
            {
                "startDate": fecha,
                "endDate": "today"
            }
        ],


    };

    if (filtros.length > 0) {
        data.dimensionFilter = {
            "filter": {
                "fieldName": dimencion,
                "inListFilter": {
                    "values": filtros,
                    "caseSensitive": false
                },
            }
        }
    }

    if (noContenga.length > 0) {
        data.dimensionFilter = {
            "notExpression": {
                "filter": {
                    "fieldName": dimencion,
                    "inListFilter": {
                        "values": noContenga,
                        "caseSensitive": false
                    },
                }
            }
        }
    }


    let dataString = JSON.stringify(data)

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://analyticsdata.googleapis.com/v1beta/properties/${PROPIEDADNUM}:runReport`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${barrer.data}`,
        },
        data: dataString
    };


    let result = await axios.request(config)

    let arrDatos = []


    if (result.status === 200 && result.data.rows) {
        let data = result.data.rows

        for (let i = 0; i < data.length; i++) {
            let dato = data[i]
            let nombre = dato?.dimensionValues[0].value
            let valor = dato?.metricValues[0].value

            let obj = {
                nombre: nombre,
                valor: parseInt(valor)
            }

            arrDatos.push(obj)

        }

    }


    return arrDatos

}