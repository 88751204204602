/**************************************************
 * Nombre:       Tabla_Prueba_Encabezado
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import Tabla_Normal from "./SubComponentes/Tablas/Tabla_Normal";
import Titulo_Filtro from "./SubComponentes/Titulos/Titulo_Filtro";


const Tabla_Filtro = ({
                          nombre = '',
                          descripcion = '',
                          filtros = [],
                          datosCabecera,
                          data,
                          numeroFilas,
                          filasPagina,
                          filtroEscogido,
                          setFiltroEscogido,
                          cambiarPagina,
                          page,
                          filasPaginaCambia,
                          estado,
                          setEstado,
                          funcionBuscar = null,
                          botones = [],
                          cargando,
                          clickAgregar = null,
                          botonesData=[],
                          clickData

                      }) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))


    const procesarFiltro = (e) => {

        let index = filtros.findIndex((fil) => fil.nombre === e)
        let filtro = filtros[index]
        let dir = filtro.direccion ? filtro.direccion : 'asc'

        setFiltroEscogido({propiedad: filtro.propiedad, direccion: dir})
    }

    return (
        <Grid2 container size={{xs: 12, sm: 12, md: 12}}
               sx={{
                   p: sCell ? 3 : 4,
                   borderRadius: 2,
                   border: '0.5px solid #EAEAEA',
                   boxShadow: '0px 8px 12px 0px #00000014',

               }}>

            <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                <Titulo_Filtro nombre={nombre} descripcion={descripcion} filtros={filtros}
                               procesarFiltro={procesarFiltro} filtroEscogido={filtroEscogido}/>
            </Grid2>

            <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{marginTop: sCell ? 3 : 4}}>
                <Tabla_Normal
                    data={data}
                    datosCabecera={datosCabecera}
                    numeroFilas={numeroFilas}
                    filasPagina={filasPagina}
                    filtroEscogido={filtroEscogido}
                    setFiltroEscogido={setFiltroEscogido}
                    page={page}
                    cambiarPagina={cambiarPagina}
                    filasPaginaCambia={filasPaginaCambia}
                    estado={estado}
                    setEstado={setEstado}
                    funcionBuscar={funcionBuscar}
                    botones={botones}
                    cargando={cargando}
                    clickAgregar={clickAgregar}
                    botonesData={botonesData}
                    clickData={clickData}

                />
            </Grid2>

        </Grid2>
    )

}
export default Tabla_Filtro



