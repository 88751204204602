import axios from "axios";
import {URLAPISUBIDAPDF, URLCARPETADESTINO} from "./Contantes";

export const subirPdf = (file) => {


    return new Promise(resolve => {

        if (!file) {
            return resolve({res: true, data: ''})
        }

        if (typeof file === 'string') {
            return resolve({res: true, data: file})

        } else {

            const formData = new FormData();
            formData.append('pdf', file);

            axios.post(URLAPISUBIDAPDF, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    return resolve({res: true, data: URLCARPETADESTINO + response.data.file_path})
                    // Handle successful upload response
                })
                .catch(error => {
                    return resolve({res: false, data: error.message})
                });


        }
    })


}