/**************************************************
 * Nombre:       Seccion_Usuarios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useObtenerAlto} from "../../Modulo_Graficas/Hooks/useObtenerAlto";
import Area_Limpia from "../../Modulo_Graficas/Graficas/Area_Limpia";
import Tarjeta_Valor_Titulo_Grafica from "../../Modulo_Graficas/Componentes/Tarjetas/Tarjeta_Valor_Titulo_Grafica";
import Dona_Limpia from "../../Modulo_Graficas/Graficas/Dona_Limpia";
import {usuarioContext} from "../../App";
import {useResponsive} from "../../Modulo_Responsive/Hooks/useResponsive";
import Linea_Limpia from "../../Modulo_Graficas/Graficas/Linea_Limpia";
import Tarjeta_Titulo_Subtitulo_3_Datos_Grafica_Larga
    from "../../Modulo_Graficas/Componentes/Tarjetas/Tarjeta_Titulo_Subtitulo_3_Datos_Grafica_Larga";
import {obtenerDatoTotalDesdeFecha} from "../../Modulo_Analitics/Funciones/obtenerDatoTotalDesdeFecha";
import dayjs from "dayjs";
import {
    DIMENCIONNOMBREPAGINA,
    DIMENCIONORIGENCANALES,
    MESES,
    TIEMPOPROMEDIONAVEGACION,
    TOTALUSUARIOS,
    USUARIOSACTIVOS,
    USUARIOSNUEVOS
} from "../../Modulo_Analitics/Constantes";
import {adap_analiticas} from "../../Modulo_Analitics/Adaptadores/adap_analiticas";
import {arrDatosYear} from "../../Modulo_Analitics/Funciones/arrDatosYear";
import {arrDatosMes} from "../../Modulo_Analitics/Funciones/arrDatosMes";
import Barra_Horizontal_Limpia from "../../Modulo_Graficas/Graficas/Barra_Horizontal_Limpia";
import {
    obtenerDatoDimencionTotalDesdeFecha
} from "../../Modulo_Analitics/Funciones/obtenerDatoDimencionTotalDesdeFecha";


const Seccion_Dashboard = () => {
    const {sCell} = useResponsive()
    const refe = useRef()
    const {alto} = useObtenerAlto({contenedor: refe})
    const cData = useContext(usuarioContext)
    const [datosAnaliticas, setDatosAnaliticas] = useState(adap_analiticas({}))
    const altoGraficas = 200
    const altoGraficasGrandes = 280


    const obtenerDatosAnaliticas = async () => {

        let fecha = dayjs(new Date(2024, 0, 1))

        let totalUsuarios = await obtenerDatoTotalDesdeFecha({
            fecha: fecha.format('YYYY-MM-DD'),
            dato: TOTALUSUARIOS,
            dimencion: DIMENCIONNOMBREPAGINA,
            noContenga: ['Admin | Sembrar Semilleros de Amor y Reconciliación']
        })
        let usuariosNuevos = await obtenerDatoTotalDesdeFecha({
            fecha: fecha.format('YYYY-MM-DD'),
            dato: USUARIOSNUEVOS,
            dimencion: DIMENCIONNOMBREPAGINA,
            noContenga: ['Admin | Sembrar Semilleros de Amor y Reconciliación']
        })
        let tiempoPromedio = await obtenerDatoTotalDesdeFecha({
            fecha: fecha.format('YYYY-MM-DD'),
            dato: TIEMPOPROMEDIONAVEGACION,
            dimencion: DIMENCIONNOMBREPAGINA,
            noContenga: ['Admin | Sembrar Semilleros de Amor y Reconciliación']
        })

        let fechaMes = dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1))
        let usuariosActivos = await obtenerDatoTotalDesdeFecha({
            fecha: fechaMes.format('YYYY-MM-DD'),
            dato: USUARIOSACTIVOS,
            dimencion: DIMENCIONNOMBREPAGINA,
            noContenga: ['Admin | Sembrar Semilleros de Amor y Reconciliación']

        })

        let origenUsuarios = await obtenerDatoDimencionTotalDesdeFecha({
            fecha: fecha.format('YYYY-MM-DD'),
            dato: USUARIOSNUEVOS,
            dimencion: DIMENCIONORIGENCANALES,
            noContenga: ['Admin | Sembrar Semilleros de Amor y Reconciliación']
        })

        let clickPagina = await obtenerDatoDimencionTotalDesdeFecha({
            fecha: fecha.format('YYYY-MM-DD'),
            dato: 'eventCount',
            dimencion: 'eventName',
            noContenga: ["abrio_formulario", "user_engagement", "Ingreso a pagina"]
        })


        let datosYearTrasAnterior = await arrDatosYear({year: new Date().getFullYear() - 2})
        let datosYearAnterior = await arrDatosYear({year: new Date().getFullYear() - 1})
        let datosYear = await arrDatosYear({year: new Date().getFullYear()})
        let datosMes = await arrDatosMes({fecha: new Date(), dato: TOTALUSUARIOS})

        console.log()


        let obj = adap_analiticas({
            totalUsuarios: parseInt(totalUsuarios),
            usuariosNuevos: parseInt(usuariosNuevos),
            tiempoPromedio: parseInt(tiempoPromedio) / parseInt(usuariosActivos) ? parseInt(usuariosActivos) : 1,
            datosYear: datosYear,
            datosYearAnterior: datosYearAnterior,
            datosYearTrasAnterior: datosYearTrasAnterior,
            datosMes: datosMes,
            origenUsuarios: origenUsuarios,
            clickPagina: clickPagina
        })


        console.log(datosYearAnterior)

        setDatosAnaliticas(obj)
    }

    useEffect(() => {


        obtenerDatosAnaliticas()


    }, []);
    return (
        <Grid2 container size={{xs: 12, sm: 12, md: 12}} justifyContent={'center'}>

            <Grid2
                container
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                size={{xs: 12, sm: 12, md: 12}}
                sx={{marginTop: 2.5, maxWidth: '1400px'}}
                spacing={3}
            >


                <Grid2 ref={refe} container size={{xs: 12, sm: 12, md: 8}}>
                    <Tarjeta_Titulo_Subtitulo_3_Datos_Grafica_Larga
                        titulo={'Usuarios en pagina'}
                        subtitulo={'Ultimos 3 años'}
                        Grafica={Linea_Limpia}
                        marginTop={12}
                        height={altoGraficas}
                        datos={datosAnaliticas}

                        series={
                            [
                                {
                                    name: new Date().getFullYear() - 2,
                                    data: datosAnaliticas?.datosYearTrasAnterior?.map((it) => it.valor)
                                },
                                {
                                    name: new Date().getFullYear() - 1,
                                    data: datosAnaliticas?.datosYearAnterior?.map((it) => it.valor)
                                },
                                {
                                    name: new Date().getFullYear(),
                                    data: datosAnaliticas?.datosYear?.map((it) => it.valor)
                                }
                            ]
                        }
                        labels={MESES}/>
                </Grid2>

                <Grid2 container size={{xs: 6, sm: 3, md: 2}} sx={{height: alto}}>
                    <Tarjeta_Valor_Titulo_Grafica valor={'Usuarios'}
                                                  titulo={'2 Tipos '}
                                                  height={altoGraficas}
                                                  Grafica={Dona_Limpia}
                                                  series={[datosAnaliticas.usuariosNuevos, datosAnaliticas.totalUsuarios - datosAnaliticas.usuariosNuevos]}
                                                  labels={['Nuevos', 'Recurentes']}/>
                </Grid2>

                <Grid2 container size={{xs: 6, sm: 3, md: 2}} sx={{height: alto}}>
                    <Tarjeta_Valor_Titulo_Grafica valor={'Ultimo'}
                                                  titulo={'Mes'}
                                                  Grafica={Area_Limpia}
                                                  height={altoGraficas}
                                                  series={
                                                      [{
                                                          name: 'Usuarios',
                                                          data: datosAnaliticas?.datosMes?.map((it) => it.valor)
                                                      }]
                                                  }
                                                  labels={datosAnaliticas?.datosMes?.map((it) => it.dia)}/>
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 6}} sx={{marginTop: {xs: -22, sm: 0}}}>
                    <Tarjeta_Valor_Titulo_Grafica valor={'Origen de Usuarios'}
                                                  titulo={'Año Actual'}
                                                  Grafica={Barra_Horizontal_Limpia}
                                                  height={altoGraficasGrandes}
                                                  maxHeight={'auto'}
                                                  series={
                                                      [{
                                                          name: 'Usuarios',
                                                          data: datosAnaliticas?.origenUsuarios?.map((it) => it.valor)
                                                      }
                                                      ]
                                                  }
                                                  labels={datosAnaliticas?.origenUsuarios?.map((it) => it.nombre)}/>
                </Grid2>

                <Grid2 container size={{xs: 12, sm: 12, md: 6}}>
                    <Tarjeta_Valor_Titulo_Grafica valor={'Click en Pagina'}
                                                  titulo={'Año Actual'}
                                                  Grafica={Barra_Horizontal_Limpia}
                                                  height={altoGraficasGrandes}
                                                  maxHeight={'auto'}
                                                  series={
                                                      [{
                                                          name: 'Usuarios',
                                                          data: datosAnaliticas?.clickPagina?.map((it) => it.valor)
                                                      }
                                                      ]
                                                  }
                                                  labels={datosAnaliticas?.clickPagina?.map((it) => it.nombre)}/>
                </Grid2>


            </Grid2>

        </Grid2>
    )

}


export default Seccion_Dashboard

