import axios from "axios";
import {obtenerBarrerAnalitycs} from "./obtenerBarrerAnalitycs";
import {PROPIEDADNUM, TOTALUSUARIOS} from "../Constantes";
import dayjs from "dayjs";

export const arrDatosMes = async ({fecha = new Date(), dato = TOTALUSUARIOS}) => {

    let barrer = await obtenerBarrerAnalitycs()

    let fechaInicio = dayjs(new Date(fecha.getFullYear(), fecha.getMonth(), 1)).format('YYYY-MM-DD')
    let fechaFin = dayjs(new Date(fecha.getFullYear(), fecha.getMonth(), 0)).add(1, 'month').format('YYYY-MM-DD')


    let data = JSON.stringify({
        "dimensions": [
            {
                "name": 'day',

            }
        ],
        "metrics": [
            {
                "name": dato
            }
        ],
        "dateRanges":
            {
                "startDate": fechaInicio,
                "endDate": fechaFin
            }
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://analyticsdata.googleapis.com/v1beta/properties/${PROPIEDADNUM}:runReport`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${barrer.data}`,
        },
        data: data
    };

    let result = await axios.request(config)

    let arrDatos = []


    if (result.status === 200 && result.data.rows) {
        let dataRow = result.data.rows

        let diasMes = dayjs(new Date(fecha.getFullYear(), fecha.getMonth(), 0)).add(1, 'month').format('D')

        for (let i = 1; i < parseInt(diasMes) + 2; i++) {
            let index = dataRow.findIndex((e) => parseInt(e.dimensionValues[0].value) === i)

            let obj = {}
            obj.dia = i
            if (index > -1) {
                obj.valor = dataRow[index].metricValues[0].value
            } else {
                obj.valor = 0
            }

            arrDatos.push(obj)
        }

    }



    return arrDatos

}