import axios from "axios";
import {obtenerBarrerAnalitycs} from "./obtenerBarrerAnalitycs";
import {PROPIEDADNUM, TOTALUSUARIOS} from "../Constantes";

export const obtenerDatoTotalDesdeFecha = async ({fecha = '2024-01-01', dato = TOTALUSUARIOS, noContenga=[], dimencion=''}) => {


    let barrer = await obtenerBarrerAnalitycs()


    let data = {
        "metrics": [
            {
                "name": dato
            }
        ],
        "dateRanges": [
            {
                "startDate": fecha,
                "endDate": "today"
            }
        ]
    };


    if (noContenga.length > 0) {
        data.dimensionFilter = {
            "notExpression": {
                "filter": {
                    "fieldName": dimencion,
                    "inListFilter": {
                        "values": noContenga,
                        "caseSensitive": false
                    },
                }
            }
        }
    }

    let dataString = JSON.stringify(data)

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://analyticsdata.googleapis.com/v1beta/properties/${PROPIEDADNUM}:runReport`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${barrer.data}`,
        },
        data: dataString
    };


    let response = await axios.request(config)

    let resultado = 0
    if (response.status === 200) {
        try {
            resultado = await response.data.rows[0].metricValues[0].value
        } catch (e) {
            resultado = 0
        }

    }

    return resultado

}