/**************************************************
 * Nombre:       MenuLateral
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Drawer, Grid2} from "@mui/material";
import {useState} from "react";
import {theme} from "../../Tema";
import {useMediaQuery} from "@mui/system";
import MainDashboard from "./MainDashboard";
import LogoMenu from "./SubComponentes/LogoMenu";
import ListaMenu from "./SubComponentes/ListaMenu";
import TarjetaUsuario from "./SubComponentes/TarjetaUsuario";

const MenuLateral = ({seccionesProcesadas, secciones, seccionesPerfil, seccionesID}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [open, setOpen] = useState(masSM)
    const anchoMax = 270
    const anchoMin = 86

    return (
        <>

            <Drawer
                onClose={() => setOpen(false)}
                open={open}
                anchor={'left'}
                variant={masSM ? 'permanent' : 'temporary'}
                PaperProps={{
                    sx: {
                        borderRight: open ? 1 : 0,
                        borderColor: '#00000020',
                        backgroundColor: theme.palette.primary.light,
                        boxShadow: open ? 0 : 12,
                        overflow: 'hidden'

                    }
                }}

            >

                <Grid2
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        width: open ? anchoMax : anchoMin,
                        px: 3,
                        paddingTop: 3,
                        transition: "all .4s ease-in-out",
                        overflow: 'hidden',
                    }}
                >


                    <LogoMenu open={open}/>

                    <ListaMenu open={open} setOpen={setOpen} seccionesProcesadas={seccionesProcesadas}/>

                    {open &&
                        <TarjetaUsuario/>
                    }

                </Grid2>


            </Drawer>


            <MainDashboard open={open} setOpen={setOpen} masSM={masSM} secciones={secciones}
                           seccionesPerfil={seccionesPerfil} seccionesID={seccionesID}/>


        </>
    )

}
export default MenuLateral
