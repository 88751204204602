/**************************************************
 * Nombre:       Casilla_Avatar_Titulo_Dato
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Avatar, Grid2, TableCell, Typography} from "@mui/material";
import {COLORTEXTO} from "../../../Constantes";

const Casilla_Avatar_Titulo_Dato = ({img, titulo, dato, dir}) => {

    return (
        <TableCell key={dato} variant={'body'} align={dir}
                   sx={{color: COLORTEXTO, fontWeight: 600, fontSize: 14, minWidth: 160}}>
            <Grid2 container size={12} alignItems={'center'}>

                <Grid2 container size={{xs: 4, sm: 3, md: 3}}>
                    <Avatar src={img}/>
                </Grid2>

                <Grid2 container size={{xs: 8, sm: 9, md: 9}}>
                    <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                        <Typography sx={{fontWeight: 600}}>
                            {titulo}
                        </Typography>
                    </Grid2>
                    <Grid2 container size={{xs: 12, sm: 12, md: 12}}>
                        <Typography sx={{fontSize: 12}}>
                            {dato}
                        </Typography>
                    </Grid2>
                </Grid2>

            </Grid2>

        </TableCell>

    )

}
export default Casilla_Avatar_Titulo_Dato