import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear'

export const obtenerFechasIniFinSemanasAtras = (semanasAtras) => {
    dayjs.extend(weekOfYear)

    let fechaInicioActual = dayjs().week(dayjs(new Date()).week())

    let arr = []
    for (let i = 0; i < semanasAtras; i++) {

        let fechaInicio = fechaInicioActual.subtract(i + 1, 'week')
        let fechaFin = fechaInicioActual.subtract(i, 'week')

        let obj = {
            fechaInicio: fechaInicio.valueOf(),
            fechaFin: fechaFin.valueOf()
        }

        arr.push(obj)
    }

    return arr
}