/**************************************************
 * Nombre:       IngresoTexto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {FileUpload,} from "@mui/icons-material";
import {useEffect, useState} from "react";


const IngresoFile = ({
                         props,
                         dato,

                     }) => {
    const {getValues, setValue, watch} = props
    const [nombre, setNombre] = useState('')

    const ingresoImagen = (data) => {
        if (data.target.files && data.target.files[0]) {
            setValue(dato, data.target.files[0])
        }
    }


    useEffect(() => {
        let obj = getValues()


        if (typeof obj[dato] === 'object') {

            setNombre(obj[dato].name)
        } else {
            setNombre(obj[dato] ? obj[dato]  : '')
        }


    }, [watch(dato)]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >

            <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'flex-start'}}>
                <Typography
                    onClick={() => nombre !== '' && nombre.length > 4 && nombre.substring(0, 4) === 'http' ? window.open(nombre) : ''}
                    sx={{cursor: nombre !== '' && nombre.length > 4 && nombre.substring(0, 4) === 'http' ? 'pointer' : ''}}
                >
                    {`...${nombre !== '' && nombre.length > 4 ? nombre.substring(nombre.length - 34, nombre.length) : ''}`}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start'}}>
                <Button

                    variant="contained"
                    component={'label'}
                    sx={{p: 2}}
                >
                    <input
                        type="file"
                        hidden
                        onChange={ingresoImagen}

                    />
                    <FileUpload color={'primary'} sx={{width: 30, height: 30, fill: '#fff'}}/>

                </Button>
            </Grid>


        </Grid>

    )

}
export default IngresoFile
