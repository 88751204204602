/**************************************************
 * Nombre:       Dona_Limpia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Chart from "react-apexcharts";
import {COLORES} from "../ContantesColorGraficas";

const Dona_Limpia = ({ancho, labels = [], series = [], marginTop=0, height='auto'}) => {
    const options = {
        chart: {
            id: "basic-bar",
            sparkline: {
                enabled: true,
            },
        },
        labels: labels,
        stroke: {
            width: 0
        },
        colors: COLORES,
    }


    return (
        <Chart
            options={options}
            series={series}
            type="donut"
            width={ancho}
           // height={height}
            style={{marginTop:marginTop }}
        />
    )

}
export default Dona_Limpia