import {obtenerFechasIniFInMesesAtras} from "./Funciones/obtenerFechasIniFInMesesAtras";
import {contarWheres} from "../Contadores/contarWheres";
import {where} from "firebase/firestore";
import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear'
import 'dayjs/locale/es'
import {obtenerFechasIniFinSemanasAtras} from "./Funciones/obtenerFechasIniFinSemanasAtras";


export const arrConteoColSemanasAtras = async ({coleccion, semanasAtras = 1}) => {
    dayjs.locale('es')
    // obtener arr de timestamp inicio mes, fin mes
    let arrFechasInicioFin = obtenerFechasIniFinSemanasAtras(semanasAtras)


    let arrPromesas = []

    for (let i = 0; i < arrFechasInicioFin.length; i++) {
        arrPromesas.push(docmentosFecha(arrFechasInicioFin[i], coleccion))
    }

    let arr = await Promise.all(arrPromesas)
    return arr.reverse()

}

const docmentosFecha = async (fechas, coleccion) => {
    dayjs.locale('es')
    dayjs.extend(weekOfYear)
    let wheres = [where('fecha', '>=', fechas.fechaInicio), where('fecha', '<=', fechas.fechaFin)]

    let result = await contarWheres({coleccion: coleccion, wheres: wheres})

    return {
        nombre: `Semana ${dayjs(fechas.fechaInicio).week()}`,
        cantidad: result.data
    }

}