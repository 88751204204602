import {enqueueSnackbar, SnackbarProvider} from 'notistack';
import {Button} from "@mui/material";

export const useAvisos = ({
                              funcionUno = () => {},
                              funcionDos = () => {}
                          }) => {

    const nuevoAviso = ({aviso, variante = 'success', Boton, persistente = false}) => {
        enqueueSnackbar(aviso, {
            variant: variante,
            action: Boton,
            persist: persistente
        })
    }

    const terminar = ({aviso = 'Tarea Finalizada'}) => {
        enqueueSnackbar(aviso, {
            variant: 'success',
            action: <Button
                variant={'text'}
                onClick={() => {
                    if (funcionUno !== null) {
                        funcionUno()
                    }
                    if (funcionDos !== null) {
                        funcionDos()
                    }
                }}
                sx={{color: '#fff'}}>Entendido</Button>,
            persist: true
        })
    }

    return {
        Avisos: SnackbarProvider,
        nuevoAviso,
        terminar

    }

}