import dayjs from "dayjs";

export const obtenerFechasIniFInMesesAtras = (mesesAtras) => {
    let fechaInicioMesActual = dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    let fechaFinMesActual = dayjs(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59))



    let arr = []
    for (let i = 0; i < mesesAtras; i++) {

        let fechaInicio = fechaInicioMesActual.subtract(i, 'month')
        let fechaFin = fechaFinMesActual.subtract(i, 'month')

        let obj = {
            fechaInicio: fechaInicio.valueOf(),
            fechaFin: fechaFin.valueOf()
        }

        arr.push(obj)
    }

    return arr
}