export const adap_analiticas = ({
                                    totalUsuarios = 0,
                                    usuariosNuevos = 0,
                                    tiempoPromedio = 0,
                                    datosYear = [],
                                    datosYearAnterior=[],
                                    datosYearTrasAnterior=[],
                                    datosMes = [],
                                    origenUsuarios = [],
                                    clickPagina = []
                                }) => {

    let obj = {
        totalUsuarios: totalUsuarios,
        usuariosNuevos: usuariosNuevos,
        tiempoPromedio: tiempoPromedio,
        datosYear: datosYear,
        datosYearAnterior: datosYearAnterior,
        datosYearTrasAnterior: datosYearTrasAnterior,
        datosMes: datosMes,
        origenUsuarios: origenUsuarios,
        clickPagina: clickPagina
    }

    return obj
}