/**************************************************
 * Nombre:       Titulo_Dato
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Typography} from "@mui/material";
import {TEXTO, TEXTOCLARO} from "../../../ContantesColorGraficas";

const Titulo_Dato = ({titulo, dato}) => {

    return (
        <Grid2
            container
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            size={{xs: 12, sm: 12, md: 12}}>

            <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{marginTop: 0.5}} >
                <Typography sx={{
                    color: TEXTOCLARO,
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.1
                }}>{titulo}</Typography>
            </Grid2>

            <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{marginTop: 0.5}}>
                <Typography
                    sx={{color: TEXTO, fontSize: 18, fontWeight: 700, lineHeight: 1.1}}>{dato}</Typography>
            </Grid2>



        </Grid2>
    )

}
export default Titulo_Dato