/**************************************************
 * Nombre:       Seccion_Usuarios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2} from "@mui/material";
import {CASILLA_ESTADO, CASILLA_FECHA_DATO} from "../../Modulo_Tabla_2025/Componentes/SubComponentes/obtenerCasilla";
import {useObtenerColleccionTabla2025} from "../../Servicios/BD/useObtenerColleccionTabla2025";
import {useDialogo} from "../../Modulo_Dialogos_2025/Hooks/useDialogo";
import React, {useContext} from "react";
import Tabla_Filtro from "../../Modulo_Tabla_2025/Componentes/Tabla_Filtro";
import Dialogo_Pqrs from "../Dialogos/Dialogo_Pqrs";
import {ManageSearch} from "@mui/icons-material";
import {ESTADOSARTICULO} from "../../Constantes";
import {usuarioContext} from "../../App";
import {useResponsive} from "../../Modulo_Responsive/Hooks/useResponsive";
import Dialogo_Articulo from "../Dialogos/Dialogo_Articulo";


const Seccion_Articulos = () => {
    const {sCell} = useResponsive()

    const cData = useContext(usuarioContext)
    const {
        props,
        cargarNuevamente,
    } = useObtenerColleccionTabla2025({coleccion: 'adicionales'})

    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Articulo,
        titulo: 'Detalles del articulo',
        realizado: cargarNuevamente
    })


    const click = (id, data) => {

        switch (id) {
            case 'detalles':
                abrir(data)
            default :
                abrir(data)
        }
    }


    return (
        <Grid2 container size={{xs: 12, sm: 12, md: 12}} justifyContent={'center'}>

            <Grid2
                container
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                size={{xs: 12, sm: 12, md: 12}}
                sx={{marginTop: 2.5, maxWidth: '1400px'}}
                spacing={3}
            >

                <Dialogo/>


                <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{marginTop: sCell ? -16 : 0}}>
                    <Tabla_Filtro
                        nombre={'Articulos de la pagina'}
                        descripcion={`${props.numeroFilas} articulos en base de datos`}
                        filtros={filtros}
                        datosCabecera={datosCabecera}
                        {...props}
                        clickAgregar={abrir}
                        botonesData={botonesData}
                        clickData={click}
                        // funcionBuscar={funcionBuscar}
                        //  botones={[]}
                    />
                </Grid2>


            </Grid2>

        </Grid2>
    )

}


export default Seccion_Articulos

const filtros = [
    {
        nombre: 'Nombre',
        direccion: 'asc',
        propiedad: 'nombre'
    },
    {
        nombre: 'Mas Reciente',
        direccion: 'desc',
        propiedad: 'fecha'
    }
]

const datosCabecera = [
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',
        fecha: 'fecha',
        tipo: CASILLA_FECHA_DATO
    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',
        tipo: CASILLA_ESTADO,
        estados: ESTADOSARTICULO
    },


]

const botonesData = [
    {
        nombre: 'Detalles',
        id: 'detalles',
        icono: ManageSearch
    }
]
