import {contarWheres} from "../Contadores/contarWheres";
import {where} from "firebase/firestore";
import 'dayjs/locale/es'


export const arrConteoColPropiedadEstados = async ({coleccion, propiedad, arrEstados = []}) => {

    let arrPromesas = []

    for (let i = 0; i < arrEstados.length; i++) {
        arrPromesas.push(documentosEstados(propiedad, arrEstados[i], coleccion))
    }

    let arr = await Promise.all(arrPromesas)
    return arr

}

const documentosEstados = async (propiedad, estado, coleccion) => {

    let wheres = [where(propiedad, '==', estado)]

    let result = await contarWheres({coleccion: coleccion, wheres: wheres})

    return {
        nombre: estado,
        cantidad: result.data
    }

}