/**************************************************
 * Nombre:       IngresoTexto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {InputAdornment, TextField} from "@mui/material";
import {Brightness1} from "@mui/icons-material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useEffect, useState} from "react";


const IngresoTextoEstilo = ({
                          props,
                          nombre,
                          dato,
                          lineas = 1,
                          Icono = Brightness1,
                          requerido = false,
                          size = 'small',
                          type = 'text',
                          editable = true
                      }) => {
    const {register, errors, getValues, watch} = props
    const [value, setValue] = useState('');


    useEffect(() => {
        props.setValue(dato, value)
    }, [value]);

    useEffect(() => {
        let obj = getValues()

        if (obj[dato]) {
            setValue(obj[dato])
        }

    }, [watch(dato)]);

    return (
        <ReactQuill theme="snow" value={value} onChange={setValue} preserveWhitespace style={{marginBottom: 40}}

        />
    )

}
export default IngresoTextoEstilo