import Casilla_Texto from "./CasillasDatos/Casilla_Texto";
import Casilla_Avatar_Titulo_Dato from "./CasillasDatos/Casilla_Avatar_Titulo_Dato";
import Casilla_Estado from "./CasillasDatos/Casilla_Estado";
import Casilla_Moneda from "./CasillasDatos/Casilla_Moneda";
import Casilla_Fecha_Dato from "./CasillasDatos/Casilla_Fecha_Dato";
import Casilla_Fecha_Datos from "./CasillasDatos/Casilla_Fecha_Datos";

export const obtenerCasilla = ({cabecera, datos, index}) => {


    switch (cabecera.tipo) {
        case CASILLA_TEXTO:
            return <Casilla_Texto key={`sd${index}`} dato={datos[cabecera.propiedad]} dir={cabecera.direccion}/>

        case CASILLA_FECHA_DATO:
            return <Casilla_Fecha_Dato key={`sdw${index}`} dato={datos[cabecera.propiedad]} fecha={datos[cabecera.fecha]} dir={cabecera.direccion}/>

        case CASILLA_FECHA_DATOS:
            return <Casilla_Fecha_Datos key={`sdws${index}`} propiedades={cabecera.propiedades} datos={datos} fecha={datos[cabecera.fecha]} dir={cabecera.direccion}/>


        case CASILLA_AVATAR_DOS_DATOS:
            return <Casilla_Avatar_Titulo_Dato key={`wree${index}`} img={datos[cabecera.propiedadUno]}
                                               titulo={datos[cabecera.propiedadDos]}
                                               dato={datos[cabecera.propiedadTres]} dir={cabecera.direccion}/>
        case CASILLA_ESTADO:
            return <Casilla_Estado key={`ter${index}`} estado={datos[cabecera.propiedad]} arrEstados={cabecera.estados}
                                   dir={cabecera.direccion}/>

        case CASILLA_MONEDA:
            return <Casilla_Moneda key={`fghf${index}`} dato={datos[cabecera.propiedad]} decimales={cabecera.decimales}
                                   dir={cabecera.direccion}/>

        default :
            return <Casilla_Texto key={`lkj${index}`} dato={datos[cabecera.propiedad]} dir={cabecera.direccion}/>

    }


}

export const CASILLA_TEXTO = 'texto'

export const CASILLA_FECHA_DATO = 'fecha_dato'

export const CASILLA_FECHA_DATOS = 'fecha_datos'
export const CASILLA_AVATAR_DOS_DATOS = 'avatar_dos_datos'
export const CASILLA_ESTADO = 'estado'

export const CASILLA_MONEDA = 'moneda'
